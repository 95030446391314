<template>
  <v-menu v-model="menu" :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :class="isFlat ? 'pt-3' : ''"
        dense
        :outlined="!isFlat"
        v-bind="attrs"
        v-on="on"
        v-model="selected"
        :flat="isFlat"
        :label="label"
        :disabled="isDisabled"
        clearable
        readonly
        :rules="isRequird ? [getRules.required] : []"
        append-icon="mdi-menu-down"
        @click:append="menu = true"
        @click:clear="clearSelection"
      ></v-text-field>
    </template>
    <div class="white py-4 scrollable organization-selector">
      <v-text-field
        class="mx-4"
        dense
        outlined
        v-model="search"
        label="Search"
        single-line
        hide-details
        clearable
        append-icon="mdi-magnify"
      ></v-text-field>
      <div v-for="(organization, index) in getAccounts" :key="`agency-${index}`">
        <div class="text-body-2 py-2 px-4 primary--text font-weight-bold">{{ organization.name | ucfirst }}</div>
        <v-treeview
          :items="organization.organizations"
          :search="search"
          @update:active="selectNode"
          class="pointer"
          hoverable
          dense
          activatable
          selection-type="independent"
          item-key="id"
          item-value="name"
          return-object
        >
          <template v-slot:label="{ item }">
            <div class="text-truncate text-body-2 pointer">{{ item.name | ucfirst }}</div>
          </template>
        </v-treeview>
      </div>
    </div>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OrganizationSelector",
  props: {
    label: {
      type: String,
      required: false,
      default: "Create As"
    },
    isFlat: {
      type: Boolean,
      default: false
    },
    isRequird: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    organization_id: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    menu: false,
    selected: null,
    search: null
  }),
  computed: {
    ...mapGetters(["getRules", "getHotelErrors", "getAccounts"])
  },
  mounted() {
    if (this.getAccounts.length > 0 && this.organization_id !== null) {
      let accounts = this.getAccounts;
      let node = [];
      _.forEach(accounts, account => {
        node = this.getChildOrganizationRecursively(account.organizations, "id", this.organization_id);
        if (node) {
          this.selectNode([node]);
          return false;
        }
      });
      this.selectNode(node);
    }
  },
  methods: {
    getChildOrganizationRecursively(array = [], key = "id", value = 0) {
      let o;
      array.some(function iter(a) {
        if (a[key] === value) {
          o = a;
          return true;
        }
        return Array.isArray(a.children) && a.children.some(iter);
      });
      return o;
    },
    clearSelection() {
      this.search = null;
      this.selected = null;
      this.$emit("org-selected", null);
    },
    selectNode(node) {
      if (node.length) {
        node.length > 0 ? (this.selected = node[0].name) : null;
        this.search = null;
        this.menu = false;
        this.$emit("org-selected", node[0].id);
      }
    }
  }
};
</script>

<style scoped>
.organization-selector {
  max-height: 300px;
  overflow-y: scroll;
}
</style>
